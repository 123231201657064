import * as React from "react";
import * as styles from './teaser.module.scss';
import { useState, useEffect } from 'react';
import { StaticImage } from "gatsby-plugin-image";
import LogoVideo from '../images/logoVideo3.webm';
import LogoPoster from '../images/logoTransparentSmall2.png';
import Twitter from '../svgs/twitter.svg';
import At from '../svgs/at.svg';
import Discord from '../svgs/discord.svg';

const Teaser = () => {
    const [formValue, setFormValue] = useState('');
    const [sentState, setSentState] = useState(false);

    useEffect(() => {
        document.addEventListener("mousemove", parallax);
    }, []);

    const handleChange = (event) => {
        setFormValue(event.target.value);
        setSentState(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setFormValue('');
        setSentState(true);

        const scriptURL = 'https://script.google.com/macros/s/AKfycbwFUE7kKN0OwtGSBuwE2zO75Ms4kFsvqmgZFw6UDerSgrJ5X0zPQZX8VAzwHuU3J8WIMg/exec';
        const form = document.forms['submit-to'];
        fetch(scriptURL, { method: 'POST', body: new FormData(form) });
    }

    const parallax = (e) => {
        const bg = document.querySelector(`.${styles.backgroundContainer}`);
        const bgCenterX = bg.offsetLeft + (bg.offsetWidth / 2);
        const bgCenterY = bg.offsetTop + (bg.offsetHeight / 2);

        document.querySelectorAll(`[parallax]`).forEach(function (move) {
            const damp = move.getAttribute("parallax");
            const mousePos = getMousePos(e.clientX, e.clientY, bg);
            const distX = mousePos.x - bgCenterX;
            const distY = mousePos.y - bgCenterY;

            const translateX = ((-1 * distX) / (12 * damp));
            const translateY = ((-1 * distY) / (12 * damp));

            move.style.transform = `scale(1.12) translate(${translateX}px, ${translateY}px)`;
        });
    }

    const getMousePos = (xRef, yRef, bg) => {
        let panelRect = bg.getBoundingClientRect();
        return {
            x: Math.floor(xRef - panelRect.left) / (panelRect.right - panelRect.left) * bg.offsetWidth,
            y: Math.floor(yRef - panelRect.top) / (panelRect.bottom - panelRect.top) * bg.offsetHeight
        };
    }

    return (
        <div className={styles.backgroundContainer}>
            {/* <StaticImage
                style={{ height: `100vh` }}
                imgStyle={{ transform: `scale(1.12)` }}
                imgClassName={styles.backgroundImage}
                src="../images/landingLayer1.jpg"
                alt="Background layer 1"
                parallax="6"
            /> */}
            <div className={styles.foregroundContainer}>
                {/* <StaticImage
                    style={{ height: `100vh` }}
                    imgStyle={{ transform: `scale(1.12)` }}
                    imgClassName={styles.foregroundImage}
                    src="../images/landingLayer2.png"
                    alt="Background layer 2"
                    parallax="2"
                /> */}
                <div className={styles.contentContainer}>
                    {/* <StaticImage
                        src="../images/logowhite.png"
                        alt="Logo"
                        className={styles.staticImage}
                    /> */}
                    <div className={styles.videoContainer}>
                        <video
                            playsInline
                            autoPlay
                            muted
                            loop
                            poster={LogoPoster}
                            >
                            <source src={LogoVideo} type='video/webm;codecs="vp8, vorbis"' />
                        </video>
                    </div>
                    {/* <p className={styles.description}>
                        raise&nbsp;&nbsp;&nbsp;breed&nbsp;&nbsp;&nbsp;battle
                    </p> */}
                    <form name="submit-to" className={styles.formContainer} onSubmit={handleSubmit}>
                        <div className={styles.emailContainer}>
                            <input className={styles.emailInput}
                                onChange={handleChange}
                                value={formValue}
                                placeholder="Enter your email"
                                type="email"
                                name="email"
                                required
                            />
                            <button className={styles.emailButton}>
                                {sentState ? 'Done' : 'Keep me updated'}
                            </button>
                        </div>
                    </form>
                    <div className={styles.mediaContainer}>
                        <a href="https://twitter.com/CrystalRivals" target="_blank" className={styles.link}>
                            TWITTER
                        </a>
                        <p className={styles.link}>|</p>
                        <a href="mailto:contact@crystalrivals.com" className={styles.link}>
                            CONTACT US
                        </a>
                        {/* <a href="https://twitter.com/CrystalRivals" target="_blank">
                            <Twitter className={styles.mediaElement} />
                        </a>
                        <a href="mailto:contact@crystalrivals.com">
                            <At className={styles.mediaElement} />
                        </a> */}
                        {/* <a href="/" target="_blank">
                            <Discord className={styles.mediaElement} />
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Teaser;
