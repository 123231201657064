import * as React from "react";
import Seo from "../components/global/seo";
import Teaser from "../components/teaser";
import "../components/global/styles.scss";

const IndexPage = () => {
  return (
    <main>
      <Seo title="Crystal Rivals" />
      <Teaser />
    </main>
  );
}

export default IndexPage;
