// extracted by mini-css-extract-plugin
export var backgroundContainer = "teaser-module--backgroundContainer--1XCV8";
export var foregroundContainer = "teaser-module--foregroundContainer--1HCNl";
export var backgroundImage = "teaser-module--backgroundImage--1hY8v";
export var foregroundImage = "teaser-module--foregroundImage--2jftn";
export var contentContainer = "teaser-module--contentContainer--rUHsU";
export var staticImage = "teaser-module--staticImage--1JB1B";
export var description = "teaser-module--description--jL5Em";
export var formContainer = "teaser-module--formContainer--1NL7a";
export var emailContainer = "teaser-module--emailContainer--lanRz";
export var emailInput = "teaser-module--emailInput--2HIkc";
export var emailButton = "teaser-module--emailButton--1OjxK";
export var link = "teaser-module--link--2mojM";
export var mediaContainer = "teaser-module--mediaContainer--25k5V";
export var mediaElement = "teaser-module--mediaElement--1FnSk";
export var videoContainer = "teaser-module--videoContainer--3N64P";